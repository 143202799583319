import React from "react";
import GlobalStyles from 'styles/GlobalStyles';

import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";

export default function App() {

  return (
    <>
      <GlobalStyles />
      <SaaSProductLandingPage />
    </>
  );
}
